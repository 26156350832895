import {
  EditMeta,
  EditedByMeta,
  Pagination,
  isNumber,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CompanyQr,
  CompanyQrOrderBy,
  CreateCompanyQr,
  PartialCompanyQr,
  PartialTalentPoolUser,
  PartialTalentPoolUserOrderBy,
  TalentPoolUser,
  TalentPoolUserStats,
  UpdateCompanyQr,
  UpdateTalentPoolUserNotesForm,
} from '../../models';
import {
  OrbiApi,
  createDynamicLink,
  getPageAndNextPage,
  v2,
} from '../../services';
import { companyActions } from '../company';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import {
  partialCompanyQrAdapter,
  partialTalentPoolUserAdapter,
} from './connect.adapter';
import { connectActions } from './connect.slice';

export const createCompanyQrThunk = createAsyncThunk<
  {
    companyQrKey: string;
    editMeta: EditMeta;
  },
  CreateCompanyQr,
  ThunkApiConfig
>('connect/create-company-qr', async (createCompanyQrParams, thunkAPI) => {
  const state = thunkAPI.getState();

  const connectModule =
    state.company.company.data?.moduleSettings.connectModule;

  if (!state.account.account.data || !connectModule) {
    thunkAPI.dispatch(setAlert('create-company-qr-code:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  if (
    isNumber(connectModule?.maxQrCodeCount) &&
    connectModule.maxQrCodeCount - connectModule.usedQrCodeCount <= 0
  ) {
    thunkAPI.dispatch(setAlert('create-company-qr-code:limit-reached'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.connect.companyQr.createCompanyQr,
    createCompanyQrParams,
  );

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('create-company-qr-code:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(companyActions.increaseUsedQrCodeCount(1));
  thunkAPI.dispatch(setAlert('create-company-qr-code:success'));

  const now = Date.now();
  const editMeta: EditedByMeta = {
    firstName: state.account.account.data.firstName,
    lastName: state.account.account.data.lastName,
    profilePicture: null,
    userKey: state.account.account.data.userKey,
  };

  return {
    companyQrKey: res.data,
    editMeta: {
      createdAt: now,
      createdBy: editMeta,
      updatedAt: now,
      updatedBy: editMeta,
    },
  };
});

export const getCompanyQrLinkThunk = createAsyncThunk<
  string,
  void,
  ThunkApiConfig
>('connect/get-company-qr-link', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.company.company.data || !state.connect.companyQr.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const shortLinkRes = await createDynamicLink({
    companyQrKey: state.connect.companyQr.data.companyQrKey,
    key: state.company.company.data.companyKey,
    page: 'company',
  });

  if (!shortLinkRes.data.shortLink) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  return shortLinkRes.data.shortLink;
});

export const getCompanyQrThunk = createAsyncThunk<
  CompanyQr,
  string,
  ThunkApiConfig
>('connect/get-company-qr', async (companyQrKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.connect.companyQr.getCompanyQr,
    companyQrKey,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getPartialCompanyQrsThunk = createAsyncThunk<
  PartialCompanyQr[],
  Pagination<CompanyQrOrderBy>,
  ThunkApiConfig
>('connect/get-partial-company-qrs', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialCompanyQrAdapter
      .getSelectors()
      .selectAll(state.connect.partialCompanyQrs.data),
    v2.connect.companyQr.getPartialCompanyQrs,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const updateCompanyQrThunk = createAsyncThunk<
  void,
  UpdateCompanyQr,
  ThunkApiConfig
>('connect/update-company-qr', async (updateCompanyQrParams, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.connect.companyQr.data) {
    thunkAPI.dispatch(setAlert('update-company-qr-code:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.connect.companyQr.updateCompanyQr, {
    ...updateCompanyQrParams,
    companyQrKey: state.connect.companyQr.data.companyQrKey,
  });

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('update-company-qr-code:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(setAlert('update-company-qr-code:success'));
  return;
});

export const getTalentPoolUserStatsThunk = createAsyncThunk<
  TalentPoolUserStats,
  undefined,
  ThunkApiConfig
>('connect/get-talent-pool-user-stats', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.connect.talentPool.getTalentPoolUserStats,
    undefined,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getTalentPoolUserThunk = createAsyncThunk<
  TalentPoolUser,
  string,
  ThunkApiConfig
>('connect/get-talent-pool-user', async (userKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.connect.talentPool.getTalentPoolUser,
    userKey,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getPartialTalentPoolUsersThunk = createAsyncThunk<
  PartialTalentPoolUser[],
  Pagination<PartialTalentPoolUserOrderBy>,
  ThunkApiConfig
>('connect/get-partial-talent-pool-users', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialTalentPoolUserAdapter
      .getSelectors()
      .selectAll(state.connect.partialTalentPoolUsers.data),
    v2.connect.talentPool.getPartialTalentPoolUsers,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const updateUserNotesThunk = createAsyncThunk<
  void,
  UpdateTalentPoolUserNotesForm,
  ThunkApiConfig
>('connect/update-user-notes', async (editUserNotesForm, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.connect.talentPoolUser.data) {
    thunkAPI.dispatch(setAlert('update-user-notes:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.connect.talentPool.updateTalentPoolUser, {
    ...editUserNotesForm,
    userKey: state.connect.talentPoolUser.data.userKey,
  });

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('update-user-notes:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(setAlert('update-user-notes:success'));
  return;
});

export const toggleTalentPoolUserIsStarredThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('connect/toggle-user-is-starred', async (userKey, thunkAPI) => {
  thunkAPI.dispatch(connectActions.toggleUserIsStarred(userKey));

  const state = thunkAPI.getState();

  const user = partialTalentPoolUserAdapter
    .getSelectors()
    .selectById(state.connect.partialTalentPoolUsers.data, userKey);

  if (!user) {
    thunkAPI.dispatch(connectActions.toggleUserIsStarred(userKey));
    thunkAPI.dispatch(setAlert('unknown'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.connect.talentPool.updateTalentPoolUser, {
    userKey,
    isStarred: user.isStarred,
  });

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(connectActions.toggleUserIsStarred(userKey));
    thunkAPI.dispatch(
      setAlert(user.isStarred ? 'star-user:error' : 'unstar-user:error', {
        fullName: `${user.firstName} ${user.lastName}`,
      }),
    );
    return thunkAPI.rejectWithValue(res);
  }

  return;
});
