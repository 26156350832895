import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Time } from '../constants';

dayjs.extend(relativeTime);
dayjs.extend(calendar);

export const dateOptions = Intl.DateTimeFormat().resolvedOptions();

export const sentryCultureContext = {
  calendar: dateOptions.calendar,
  locale: dateOptions.locale,
  timezone: dateOptions.timeZone,
};

const formattingOptions = [
  'timeAgo',
  'M',
  'H',
  'D',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm',
  'DD MMM YYYY HH:mm',
  'DD MMM YYYY, HH:mm',
  'YYYY-MM-DD HH:mm',
  'DD MMM YYYY',
  'DD MMM YYYY HH:mm:ss',
  'ddd DD MMM',
] as const;

export type DateFormat = (typeof formattingOptions)[number];

export const parseTimestamp = (
  timestamp: number,
  dateFormat: DateFormat,
  options?: dayjs.OptionType,
) => {
  if (dateFormat === 'timeAgo') {
    return dayjs(timestamp, options).fromNow();
  }

  return dayjs(timestamp, options).format(dateFormat);
};

export const setStartOfDay = (date: string | number) => {
  return new Date(date).setHours(0, 0, 0, 0);
};

export const setEndOfDay = (date: string | number) => {
  return new Date(date).setHours(23, 59, 59, 999);
};

export function formatPublishDate(timestamp: number) {
  const date = dayjs(timestamp).calendar(null, {
    sameDay: '[Today at] HH:mm',
    nextDay: '[Tomorrow at] HH:mm',
    nextWeek: 'dddd [at] HH:mm',
    lastDay: '[Yesterday at] HH:mm',
    lastWeek: '[Last] dddd [at] HH:mm',
    sameElse: 'DD/MM/YYYY [at] HH:mm',
  });

  return date;
}
export function getPublishMenuItems() {
  const values: {
    type: 'tomorrow_at_7' | 'today_at_18';
    value: number;
  }[] = [
    {
      type: 'tomorrow_at_7',
      value: new Date().setHours(7, 0, 0, 0) + Time.Day,
    },
  ];

  const currentDate = new Date();
  const currentHours = currentDate.getHours();

  if (currentHours < 18) {
    values.unshift({
      type: 'today_at_18',
      value: new Date().setHours(18, 0, 0, 0),
    });
  }

  return values;
}
